<template>
    <div class="header">
        <div class="header__inner">
            <button
                v-if="backButtonActive"
                class="header__button back"
                @click="$router.back()"
            >
                <Icon icon="arrow-back"/>
                <span class="header__button__label">Back</span>
            </button>
            <router-link
                class="logo"
                :class="{ 'logo--constrained': backButtonActive }"
                to="/"
            >
                <img v-if="backButtonActive && entity" class="logo__image square" :alt="entity.name +' logo'" :src="entity.logo_url">
                <img v-if="!backButtonActive && entity" class="logo__image" :alt="entity.name +' logo'" :src="entity.logo_url">
            </router-link>
            <router-link
                class="header__map"
                :to="{ name: 'map' }"
            >
                <Icon icon="pin" />
            </router-link>
            <router-link
                class="header__cart"
                :to="{ name: 'cart' }"
            >
                <span
                    v-if="cart.items.length"
                    class="cart-quantity"
                >
                    {{ cart.items.length }}
                </span>
                <Icon icon="shop"/>
            </router-link>
        </div>
    </div>
</template>


<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useCart, useState } from '@/store';
import { useRouterDepth } from '@/hooks/useRouterDepth';

export default defineComponent({
    name: 'Header',
    props: {
        getCartCount: {
            type: Number,
        },
    },
    setup() {
        const entity = useState('entity');
        const cart = useCart();

        const depth = useRouterDepth();
        const backButtonActive = computed(() => depth.value > 1);

        return {
            backButtonActive,
            entity,
            cart,
        };
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $header-background;
    color: text-contrast($header-background);
    z-index: 9999;
    box-shadow:
        0 1px 2px 0 rgba(0,0,0,0.14),
        0 2px 2px -1px rgba(0,0,0,0.12),
        0 1px 6px 0 rgba(0,0,0,0.2);
}

.header__inner {
    display: flex;
    align-items: center;
    padding: 0 $container-gutter;
    height: $header-height;
    gap: $container-gutter;

    @media screen and (min-width: 48em) {
        margin: auto;
        width: var(--container-width);
    }
}

.logo {
    flex: 0 0 auto;
    margin-right: auto;
    display: grid;
    place-items: center;
    max-width: 140px;

    &.logo--constrained {
        max-width: 108px;
    }

    &__image {
        height: 40px;
        max-width: min(140px, 100%);
        object-fit: contain;
        object-position: left center;
    }
}

.header__button {
    flex: 0 0 $header-button-width;
    background: none;
    color: inherit;
    font: inherit;
    text-decoration: none;
    border: none;
    box-sizing: border-box;

    font-size: $fs-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $header-button-height;
    width: $header-button-width;
    border-radius: 0;
    overflow: hidden;
    opacity: 0.75;
    border-bottom: 2px solid transparent;
    transition:
        color 200ms ease,
        background-color 200ms ease,
        border 200ms ease,
        border-radius 200ms ease;

    &.shop {
        flex-basis: 58px;
    }

    &:is(a) {
        grid-column: -3;
    }

    &:last-child {
        margin-right: 0;
        grid-column: -2;
    }

    &:deep() svg {
        width: 24px;
        height: 24px;

        path,
        ellipse,
        circle,
        line,
        rect {
            stroke: currentColor;
        }
    }

    &__label {
        display: block;
        text-align: center;
        line-height: 1.333;
        margin-top: 0.25em;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        text-transform: capitalize;
    }

    &.router-link-exact-active {
        border-bottom-color: currentColor;
        color: currentColor;

        @if $navigation-background == #fff {
            color: $accent-01;
        }
    }

    &:hover {
        background-color: rgba(text-contrast($header-background), 0.06);
        color: text-contrast($header-background);
        border-color: transparent;
        border-radius: 4px;

         @if $header-background == #fff {
            background-color: rgba($accent-01, 0.06);
            color: $accent-01;
        }
    }

    &:focus {
        background-color: rgba(text-contrast($header-background), 0.12);
        border-color: transparent;
        color: text-contrast($header-background);
        border-radius: 4px;

        @if $header-background == #fff {
            background-color: rgba($accent-01, 0.12);
            color: $accent-01;
        }
    }

    &.back {
        flex-basis: 58px;
        padding-left: $container-gutter;
        padding-right: $container-gutter;
        margin: -12px 0 -12px calc(#{$container-gutter} * -1);
        height: 56px;
        border-radius: 0;
        border-right: 1px solid $grey-02;
        margin-right: 4px;

        .header__button__label {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:deep() svg {
            width: 18px;

            path,
            ellipse,
            circle,
            line,
            rect {
                stroke: transparent;
                fill: $text-colour;
            }
        }
    }

}
.header__cart,
.header__map {
    color: inherit;

    &.router-link-active {
        color: $accent-01;
    }
}

.header__cart {
    position: relative;
}

.cart-quantity {
    position: absolute;
    left: -4px;
    bottom: -4px;
    background-color: $accent-01;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 10px;
    display: grid;
    place-items: center;
    color: $accent-01-text;
    font-weight: 700;
}

</style>
