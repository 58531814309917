<template>
    <div class="popup-onboarding">
        <h1 class="popup-onboarding__heading">Welcome</h1>
        <p>Let us customise what we show you by answering these 2 quick questions:</p>

        <form ref="formRef">
            <FieldDatePicker
                name="departure_date"
                label="Your Departure Date"
                v-model:value="selectedDate"
                openPosition="inline"
                :showNavigation="true"
                :modifierStyles="['inline-centered', 'hidden-input-arrow', 'smaller-width']"
                required
            />
            <FieldDropdown
                required
                name="accommodation_type"
                label="Your accommodation type"
                v-model:value="selectedAccomodation"
                :options="accommodationOptions"
                :modifierStyles="['accommodation']"
            />
            <div class="popup-onboarding__cta__container">
                <Button
                    label="Skip This"
                    @click="onSubmit('skipped')"
                />
                <Button
                    label="Submit"
                    @click="onSubmit('complete')"
                />
            </div>
        </form>

    </div>

</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useUser } from '@/store';
import { ClientOnboarding } from '@/types';
import Button from '@/components/atoms/Button.vue';
import FieldDatePicker from "@/components/fields/FieldDatePicker.vue";
import FieldDropdown from "@/components/fields/FieldDropdown.vue";

// const isCalendarHidden = ref(false);
const user = useUser();
const formRef = ref<HTMLFormElement>();
const selectedDate = ref(new Date());
const selectedAccomodation = ref("");

const accommodationOptions = [
    {
        text: 'Site',
        value: '1',
    },
    {
        text: 'Cabin',
        value: '2',
    },
    {
        text: 'Glamping',
        value: '3',
    },
];

type OnboardState = ClientOnboarding['onboardState'];

const onSubmit = (state: OnboardState) => {
    if (state === 'complete') {
        const form = formRef.value!;

        if (!(form.checkValidity())) {
            // TODO: trigger FieldDropdown/FieldDatePicker's validateField for this?
            form.reportValidity();
            return;
        }

        // Normalise to midnight
        selectedDate.value.setHours(0, 0, 0, 0);
        user.set('departureDate', selectedDate.value);
        user.set('accommodationType', selectedOption.value!.text);
    }

    user.set('onboardState', state);
}

const selectedOption = computed(() => {
    return accommodationOptions.find(option => option.value === selectedAccomodation.value)
});

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.popup-onboarding {
    padding: 1rem;

    @media screen and (max-width: 376px) {
        padding-inline: 0.1rem;
    }

    &__heading {
        color: $black;
        font-weight: bolder;
        text-align: center;
        font-size: 24px;
    }

    & > p {
        margin: 0;
    }

    :deep() .field-label {
        display: inline-block;
        margin-top: 1.75rem;
        margin-bottom: 0.75rem;
        font-size: 16px;
        font-weight: 700;
        color: $grey-11;

        &__required {
            color: $accent-01;
        }
    }

    &__cta__container {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;

        & > button {
            width: 48%;

            &:first-child {
                color: text-contrast($grey-03);
                background-color: $grey-03;
                border-color: $grey-03;
            }
        }
    }
}
</style>

