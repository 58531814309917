<template>
    <div
        :class="[
        { 'scaffold--navigation--rebook-padding scaffold--navigation--nav-no-box-shadow': user.showRebookPrompt },
        { 'scaffold--navigation--no-padding-top': !showHeader },
        'scaffold scaffold--navigation',
        contextClass
        ]"
    >
        <Header v-if="showHeader" />
        <router-view />
        <RebookPrompt v-if="user.showRebookPrompt" />
        <Navigation />
    </div>

    <Popup
        v-if="cart.cartMeta.isItemAdded"
    >
        <PopupAddToCart v-slot/>
    </Popup>

    <Popup
        v-if="user.get('onboardState') === 'incomplete'"
        top-z-index
    >
        <PopupOnboarding v-slot/>
    </Popup>

</template>

<script lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import Header from '@/components/Header.vue';
import Popup from '@/components/popups/Popup.vue';
import PopupAddToCart from '@/components/popups/PopupAddToCart.vue';
import PopupOnboarding from '@/components/popups/PopupOnboarding.vue';
import RebookPrompt from '@/components/RebookPrompt.vue';
import Navigation from '@/components/Navigation.vue';
import { useCart, useUser } from '@/store';
import { currentTime } from '@/store/user';
import { useStayWebSocket } from '@/hooks';

export default {
    name: 'Scaffold',
    components: {
        Header,
        Popup,
        PopupAddToCart,
        PopupOnboarding,
        RebookPrompt,
        Navigation,
    },
    props: {
        contextClass: String,
        showHeader: {
            type: Boolean,
            default: true,
        }
    },
    setup() {
        const cart = useCart();
        const user = useUser();
        const ws = useStayWebSocket();

        // TODO: Move this logic to user store
        const intervalId = ref();

        onMounted(() => {
            intervalId.value = setInterval(() => currentTime.value = new Date(), 1000);
        });

        onUnmounted(() => {
            clearInterval(intervalId.value);
        });

        return {
            cart,
            user,
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.scaffold {
    height: 100%;
}

.scaffold--navigation {
    overflow: auto;
    padding-top: calc(#{$taskbar-height} + #{$header-height});
    padding-bottom: $navigation-height;

    background-image: url(/images/ellipse-fade.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    &--no-padding-top {
        padding-top: 0;
    }

    &--rebook-padding {
        padding-bottom: $rebook-prompt-height;
    }

    &--nav-no-box-shadow {
        :deep() .nav__inner {
            box-shadow: none;
        }
    }
}

:deep() {
    .popup-content {
        top: 48% !important; // TODO: don't do like this..

        max-height: calc(100% - 50px);
        overflow-y: auto;
    }
}
</style>
