import { reactive, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Module, useStore } from 'vuex'
import { Client, ClientOnboarding, ClientChat } from '@/types';
import type { State as RootState } from "./index";
import * as API from '@/api';

export interface State extends Client, ClientOnboarding, ClientChat {}

export const EMPTY: State = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    phone: '',
    onboardState: 'incomplete',
};

// TODO: terrible - refactor this
// currentTime is updated in Scaffold (onMounted) when it should
// just be an action/mutation here on a non-persistent store
export const currentTime = ref(new Date());

export const showRebookPrompt = computed(() => {
    const store = useStore<RootState>();
    const route = useRoute();

    let departure = store.state.user.departureDate;

    if (store.state.user.onboardState !== 'complete' || route.name === 'rebook' || !departure) {
        return false;
    }

    // May be nice to implement VuexPersistence restoreState
    // so that this is a Date type (and not string) following
    // localStorage -> store deserialisation

    // Let's make a copy of the date for 24h calculations
    departure = new Date(departure);

    // Let's show it 24h before departure
    departure.setDate(departure.getDate() - 1);
    return currentTime.value > departure;
});

export const module: Module<State, any> = {
    namespaced: true,
    state: () => EMPTY,
    mutations: {
        setValue(state: State, value: Partial<State>) {
            Object.assign(state, value);
        }
    },
    actions: {
        // async init({ commit }) {
        //     const entity = await API.getEntity();
        //     commit('setValue', { entity });
        // },
    },
}

export function useUser() {
    const store = useStore<RootState>();

    function get(key: keyof State) {
        return store.state.user[key];
    }

    function set(key: keyof State, value: State[keyof State]) {
        store.commit('user/setValue', { [key]: value });
    }

    return reactive({
        details: store.state.user,
        showRebookPrompt,
        get,
        set,
    });
}
