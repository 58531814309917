<template>
    <div id="nav">
        <div class="nav__inner" v-if="entity">

            <router-link class="nav__link" :to="{name: 'park-group'}">
                <Icon icon="star"/>
                <span class="nav__link__label">
                    In-{{ typeShortName }}
                </span>
            </router-link>
            <router-link v-if="entity.content_settings.explore.display" class="nav__link" :to="{name: 'local-group'}">
                <Icon icon="explore"/>
                <span class="nav__link__label">
                    {{ entity.content_settings.explore.name || "Local Guide" }}
                </span>
            </router-link>
            <router-link v-if="entity.content_settings.shop.display" class="nav__link" :to="{name: 'shop'}">
                <Icon icon="shop"/>
                <span class="nav__link__label">
                    {{ entity.content_settings.shop.name || "Kiosk" }}
                </span>
            </router-link>
            <router-link v-if="entity.content_settings.messages.display" class="nav__link" :to="{name: 'chat'}">
                <Icon icon="conversation-chat-text"/>
                <span class="nav__link__label">
                    {{ entity.content_settings.messages.name || "StayAI" }}
                </span>
            </router-link>
            <router-link v-if="entity.content_settings.info.display" class="nav__link" :to="{name: 'info'}">
                <Icon icon="information"/>
                <span class="nav__link__label">
                    {{ entity.content_settings.info.name || `${typeShortName} Info` }}
                </span>
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useState } from '@/store';

export default defineComponent({
    name: 'Header',
    components: {
    },
    setup() {
        const entity = useState('entity');
        const typeShortName = useState('shortEntityType');

        return {
            entity,
            typeShortName,
        }
    },
});

</script>


<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
    #nav {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        z-index: 9999;

        color: text-contrast($navigation-background);
        background-color: $navigation-background;
        border-top: 0.5px solid rgba(text-contrast($navigation-background), 0.25);
        box-shadow:
            0 0 2px 0 rgba(0,0,0,0.1),
            0 0 5px 0 rgba(0,0,0,0.15),
            0 0 15px 0 rgba($black, 0.22);
        transition: box-shadow 200ms ease;
    }

    .nav__inner {
        position: relative;
        display: grid;
        grid-auto-flow: column;
        height: $navigation-height;

        @media screen and (min-width: 48em) {
            margin: auto;
            width: var(--container-width);
        }
    }

    .nav__link {
        display: grid;
        place-items: center;
        color: inherit;
        text-decoration: none;
        padding: 8px 0 6px;
        border-bottom: 3px solid transparent;
        opacity: 0.7;

        &__label {
            display: block;
            font-size: $fs-small;
            line-height: 1.333;
        }

        &:deep() svg {
            width: 24px;
            height: 24px;
        }

        &.router-link-active,
        &.router-link-exact-active {
            opacity: 1;
            border-top-color: rgba(text-contrast($navigation-background), 0.2);
            border-bottom-color: currentColor;
            color: currentColor;

            @if $navigation-background == #fff {
                color: $accent-01;
            }
        }
    }
</style>
