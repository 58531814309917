<template>
    <div
        :class="{
            'svg-wrap': true,
            'svg-wrap--fill': colorTarget == 'both' || colorTarget == 'fill',
            'svg-wrap--stroke': colorTarget == 'both' || colorTarget == 'stroke',
        }"
        :style="{
            color,
            width: size,
            height: size,
        }"
        v-html="svgContent"
    />
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs, onMounted, PropType } from 'vue';

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true,
        },
        size: {
            type: String,
        },
        color: {
            type: String,
        },
        colorTarget: {
            type: String as PropType<'none' | 'fill' | 'stroke' | 'both'>,
            default: 'stroke',
        },
    },
    setup(props) {
        const {icon} = toRefs(props);
        const svgContent = ref("");

        onMounted(() => {
            loadIcon(icon.value);
        });

        watch(icon, loadIcon);

        async function loadIcon(icon: string) {
            if (!icon) {
                svgContent.value = '';
                return;
            }

            const res = await fetch(`/images/icons/${icon}.svg`);

            if (!res.ok) {
                console.warn('Icon not found:', icon);
                svgContent.value = '';
                return;
            }

            svgContent.value = await res.text();
        }

        return {
            svgContent,
        };
    }
})
</script>

<style scoped lang="scss">
    @import '@/assets/scss/variables.scss';

    .svg-wrap {
        display: flex;

        &--stroke :deep() svg * {
            stroke: currentColor;
        }

        &--fill :deep() svg * {
            fill: currentColor;
        }
    }
</style>
