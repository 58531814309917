import { ref, onUnmounted } from 'vue';
import { useWebSocket } from '@vueuse/core';
import { ContentSettings, ThreadMessageRole } from '@/api';
import store from '@/store';

interface BaseWebSocketsMessage {
  target: string;
}

interface ChatMessage extends BaseWebSocketsMessage {
  type: "chat";
  message: string;
  topic: string;
  sender: ThreadMessageRole;
}

interface BroadcastMessage extends BaseWebSocketsMessage {
  type: "broadcast";
  message: string;
  topic?: string;
  sender?: ThreadMessageRole;
}

interface BroadcastContentSettings {
  type: "content-settings";
  data: ContentSettings;
}

interface BroadcastCodeRefresh {
  type: "code-refresh";
  data: {};
}

interface InfoMessage extends BaseWebSocketsMessage {
  type: "info";
  add_topic?: string;
  remove_topic?: string;
  activity_status?: ActivityStatus;
}

export type ActivityStatus = 'idle' | 'working' | 'awaiting-user' | 'error';

type WebSocketsMessage = ChatMessage | BroadcastMessage | InfoMessage | 'pong';
type BroadcastMessageTypes = BroadcastContentSettings | BroadcastCodeRefresh;


// Singleton state
const wsConfig = {
  base_url: window.__stay__.ws_url,
  entity_uid: window.__stay__.base_entity_uid,
  reconnectInterval: 2000,
  reconnectAttempts: Infinity,
}

const chatMessage = ref<ChatMessage | null>(null);
const agentStatus = ref<ActivityStatus>('idle');
const broadcastMessage = ref({});

function parseBroadcastMessage(message: BroadcastMessageTypes) {
  // My feeling is that for most things, views don't need to be able to read broadcasts
  // so we will just directly run associated logic here.
  switch (message.type) {
    case 'content-settings': {
      // Broadcast tests:
      // 1. Normal: {"type": "content-settings", "data": {"entity":{"display":false,"name":""},"shop":{"display":false,"name":""},"hire":{"display":true,"name":""},"reservable":{"display":true,"name":""},"event":{"display":true,"name":""},"messages":{"display":true,"name":"Test"},"problems":{"display":false,"name":""},"tasks":{"display":false,"name":""},"orders":{"display":false,"name":""},"performance":{"display":false,"name":""},"notifications":{"display":false,"name":""},"entity-finance":{"display":false,"name":""},"explore":{"display":true,"name":""},"explore-events":{"display":false,"name":""},"explore-attractions":{"display":true,"name":""},"explore-food":{"display":true,"name":""},"info":{"display":true,"name":""},"info-contact":{"display":true,"name":""},"info-faq":{"display":true,"name":""},"info-facilities":{"display":true,"name":""},"info-map":{"display":true,"name":""},"deals":{"display":true,"name":""},"stay-ai":{"display":false,"name":""},"user":{"display":false,"name":""},"user-access":{"display":false,"name":""}}}
      // 2. All off: {"type": "content-settings", "data": {"entity":{"display":false,"name":""},"shop":{"display":false,"name":""},"hire":{"display":false,"name":""},"reservable":{"display":false,"name":""},"event":{"display":false,"name":""},"messages":{"display":false,"name":"Test"},"problems":{"display":false,"name":""},"tasks":{"display":false,"name":""},"orders":{"display":false,"name":""},"performance":{"display":false,"name":""},"notifications":{"display":false,"name":""},"entity-finance":{"display":false,"name":""},"explore":{"display":false,"name":""},"explore-events":{"display":false,"name":""},"explore-attractions":{"display":false,"name":""},"explore-food":{"display":false,"name":""},"info":{"display":false,"name":""},"info-contact":{"display":false,"name":""},"info-faq":{"display":false,"name":""},"info-facilities":{"display":false,"name":""},"info-map":{"display":false,"name":""},"deals":{"display":false,"name":""},"stay-ai":{"display":false,"name":""},"user":{"display":false,"name":""},"user-access":{"display":false,"name":""}}}

      const entity = store.state.app.entity;
      if (entity)
        store.commit('app/setValue', { entity: {
          ...entity,
          "content_settings": {
            ...entity["content_settings"],
            ...message.data
          }
        }
      });

      break;
    }

    case 'code-refresh': {
      console.log('Code refresh');
      break;
    }

    default: {
      console.warn('Unknown broadcast message type', broadcastMessage);
    }
  }
}

function parseInfoMessage(message: InfoMessage) {
  if (message.activity_status) {
    agentStatus.value = message.activity_status;
  }
}

function parseMessage(message: string) {
  try {
    const parsed = JSON.parse(message);

    if ("type" in parsed) {
      switch (parsed.type) {
        case 'chat': {
          chatMessage.value = parsed;
          break;
        }

        case 'broadcast': {
          try {
            const broadcastMessage = JSON.parse(parsed.message);

            parseBroadcastMessage(broadcastMessage);
          } catch (e) {
            console.warn('Failed to parse broadcast message', e);
          }
        }

        case 'info': {
          parseInfoMessage(parsed);

          break;
        }

        default: {
          console.warn('Unknown message type', parsed);
        }
      }
    }
  } catch (e) {
    console.warn('Failed to parse message', e);
  }
}

const { status, data, send, open, close } = useWebSocket(`${wsConfig.base_url}?target=${wsConfig.entity_uid}`, {
  autoReconnect: {
    retries: wsConfig.reconnectAttempts,
    delay: wsConfig.reconnectInterval,
  },
  // Automatic heartbeat response
  onMessage: (ws, event) => {
    const message = event.data;

    if (message === 'ping') {
      ws.send('pong');
      return;
    }

    parseMessage(message);
  }
});

export function useStayWebSocket() {
  onUnmounted(() => {
    if (status.value === 'OPEN') {
      // close();
    }
  });

  return { status, data, chatMessage, agentStatus, send, open, close };
}
