<template>
    <div
        class="
            field-element
            field-element--dropdown
            field-element--dropdown--placeholder"
        :class="[{
            'field-element--required': required,
            'field-element--disabled': disabled,
            'field-element--error': false,
        }, modifierClasses ]"
    >
        <label
            v-if="label"
            :for="name"
            class="field-label"
        >
            {{ label }}
            <span v-if="required" class="field-label__required">required</span>
        </label>
        <div class="field-input">
            <DatePicker
                :class="['pseudo-date-picker']"
                :name="name"
                :min-date="new Date()"
                :show-dropdowns="false"
                :show-navigation="true"
                :modelValue="value"
                :openPosition="openPosition"
                @update:modelValue="$emit('update:value', $event)"
                @update:month="$emit('update:month', $event)"
            >
            </DatePicker>
        </div>
    </div>
</template>

<script>
import { DatePicker } from '@karmabunny/date-picker';

export default {
    name: 'FieldDatePicker',
    components: {
        DatePicker
    },
    props: {
        name: {
            type: String,
        },
        helper: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        placeholder: {
            type: String,
            default: 'Select a date',
        },
        modifierStyles: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => new Date,
        },
        openPosition: {
            type: String,
            default: 'auto',
        }
    },
    computed: {
        modifierClasses() {
            return this.$props.modifierStyles
                .map(value => 'field-element--' + value)
                .join(' ');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/forms.scss';

.field-label {
    z-index: 1;
}

.pseudo-date-picker :deep() {
    @import "@karmabunny/date-picker/src/assets/date-picker.scss";

    padding: 0;
    max-width: 100%;
    min-height: unset;

    table, td, th {
        border: none;
    }

    td {
        &.depart {
            background: linear-gradient(to bottom right, #fff 0 50%, #ddd 50% 100%);
        }

        &.disabled:not(.time-travel) {
            opacity: 1;
            background-color: #eee;
            color: #bbb;
            &::after {
                display: none;
            }
        }

        &.in-,
        &.start- {
            background: $accent-01;
            color: #eee;
        }

        &.in-range,
        &.start-range,
        &.end-range  {
            background: $accent-01 !important;
            color: #eee;
        }
    }

    .date-picker__input {
        @include field-textbox;
        @include field-styles;
        background-image:
            url(/images/fields/field_datepicker.svg),
            url(/images/fields/field_arrow-down.svg),
            url(/images/fields/field_background-fill.svg);
        background-repeat:
            no-repeat,
            no-repeat,
            repeat-Y;
        background-position:
            center left 12px,
            center right calc((40px - #{$form-icon-arrow-down}) / 2),
            center right;
        background-size:
            16px,
            $form-icon-arrow-down,
            40px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.field-element--white .pseudo-date-picker :deep() .date-picker__input {
    border-color: $grey-02;
    background-color: $white;
}

.field-element--full-width {
    .pseudo-date-picker {
        width: 100%;
        max-width: initial;

        :deep() .date-picker__input {
            width: 100%;
        }
    }
}

.field-element--smaller-width {
    :deep() .date-picker__dropdown--inline {
        min-width: 250px;
    }
}

.field-element--hidden-input-arrow {
    :deep() .date-picker__input {
        background-image: url(/images/fields/field_datepicker.svg);
        text-align: center;
    }
}

.field-element--inline-centered {
    :deep() .date-picker__calendars, :deep() .calendar {
        margin-inline: auto;
    }
}

</style>
