<template>
    <div class="container">
        <div class="filter-types-wrap fade-in-left">
            <template v-for="item, index in items">
                <router-link
                    v-if="entity?.content_settings[item.content_setting]?.display"
                    class="filter-type-button"
                    active-class="active"
                    :to="item.to"
                >
                    {{ tabLabels[index] }}
                </router-link>
            </template>
        </div>

        <slot>
            <router-view/>
        </slot>

    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { TabItem } from '@/types';
import { useState } from '@/store';

export default defineComponent({
    name: 'TabGroupSub',
    components: {
    },
    props: {
        items: {
            type: Array as PropType<TabItem[]>,
            required: true,
        },
    },
    setup(props, context) {
        const entity = useState('entity');

        const tabLabels = computed(() => {
            return props.items.map((item) => {
                const entityContentSectionName = entity.value?.content_settings[item.content_setting]?.name;
                if (entityContentSectionName !== '') {
                    return entityContentSectionName;
                }

                return item.label;
            });
        });

        return {
            entity,
            tabLabels,
        }
    }
})
</script>


<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/forms.scss';

.filter-types-wrap {
    margin: 0 0 var(--container-gutter);
    display: grid;
    gap: 0.5em;
    text-align: center;

    // Loosey goosey
    grid-auto-flow: column;
    justify-content: stretch;

    // Fixed
    // grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    // justify-content: center;

    .filter-type-button {
        display: grid;
        place-items: center;
        padding: 0.6em 0.25em;
        border: 2px solid $grey-02;
        border-radius: 3px;
        background: $grey-02;
        font-size: 14px;
        line-height: 1;
        text-decoration: none;
        color: $grey-07;
        font-weight: 600;

        &:not(:first-child) {
            margin-left: -2px;
        }

         &.active {
            position: relative;
            background-color: rgba($accent-02, 0.2);
            border-color: $accent-02;
            color: $accent-02;

            &:before,
            &:after {
                content: " ";
                position: absolute;
                top: calc(100% + 2px);
                left: 14px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 0;
                height: 0;
                color: $accent-02;
                border-color: $accent-02 transparent transparent;
                border-style: solid;
                border-width: 4px 4px 0 4px;
                z-index: 1;
            }

            &:after {
                background: $grey-01;
                top: 100%;
                border-color: rgba($accent-02, 0.2) $accent-02 $accent-02;
                border-width: 3px 3px 0 3px;
            }
        }
    }
}
</style>
