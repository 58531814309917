import './preboot';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Icon from '@/components/abstracts/Icon.vue';
import Loader from '@/components/abstracts/Loader.vue';
import * as Sentry from "@sentry/vue";

const app = createApp(App)

app.use(store);
app.use(router);
app.mixin({
    components: {
        Icon,
        Loader,
    }
});

Sentry.init({
    app,
    dsn: "https://2b48ef61afbb0f172d4f5d98e9692f4c@o4505366428975104.ingest.sentry.io/4506073679265792",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                /^https?:\/\/cloud\.theystay\.app/,
                /^https?:\/\/theystay\.app/,
                /^https?:\/\/stay\.bunnyqa\.com/,
                /^https?:\/\/stay-app\.bunnyqa\.com/,
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


app.mount('#app');
