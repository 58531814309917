import { Store, createStore } from 'vuex';

import * as App from './app';
import * as Cart from './cart';
import * as User from './user';
import VuexPersistence from 'vuex-persist';
import { EntityStorage } from './storage';

export { useState } from './app';
export { useCart } from './cart';
export { useUser } from './user';

export type { CartItemState } from './cart';

// TODO we probably don't need this if we're using hooks from here on.
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $store: Store<State>
    }
}

export interface State {
    app: App.State;
    cart: Cart.State;
    user: User.State;
}

const storage = new EntityStorage('stayapp:' + window.__stay__.base_entity_uid);

const persist = new VuexPersistence<State>({
    storage: storage,
    key: 'persist',
    modules: [
        'cart',
        'user',
    ],
});

export default createStore({
    modules: {
        app: App.module,
        cart: Cart.module,
        user: User.module,
    },
    plugins: [
        persist.plugin,
    ],
})
