<template>
    <div class="rebook_prompt">
        <div class="rebook_prompt__inner">
            <Button
                isRouter
                :to="{ name: 'rebook' }"
                icon="calendar-check-1"
                class="rebook_prompt__cta"
                label="Book Your Next Stay"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from '@/components/atoms/Button.vue';
import { useUser } from '@/store';

export default defineComponent({
    name: 'Header',
    components: {
        Button,
    },
    setup() {
        const user = useUser();

        return {
            user,
        }
    },
});

</script>


<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
    .rebook_prompt {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        z-index: 9999;

        &__inner {
            position: relative;
            background-color: $navigation-background;
            color: text-contrast($navigation-background);
            display: grid;
            grid-auto-flow: column;
            height: $rebook-prompt-height;
            box-shadow:
                0 0 2px 0 rgba(0,0,0,0.1),
                0 0 5px 0 rgba(0,0,0,0.15),
                0 0 15px 0 rgba($black, 0.22);
            transition: box-shadow 200ms ease;
        }

        &__cta {
            display: flex;
            column-gap: 1rem;
            justify-content: center;
            align-items: center;
            color: $accent-01; // TODO: will look bad with light primary accent
            background-color: $grey-02;
            border-color: $grey-02;
            width: 100%;
            border-radius: 0px;
            height: calc($rebook-prompt-height - $navigation-height);

        }
    }

</style>
