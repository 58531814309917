<template>
    <div :class="{
        'loader': true,
        'loader--overlay': overlay,
    }">
        <div v-if="overlay" class="loader__mask"></div>
        <p v-if="showText">Loading...</p>
        <span class="spin"></span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Loader',
    props: {
        overlay: {
            type: Boolean,
            default: false,
        },
        showText: {
            type: Boolean,
            default: true,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        padding-left: 1ch;
    }

    &--overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;

        p, .spin {
            color: #fff;
            z-index: 1000;
        }

        .spin:before {
            border: solid 5px #fff;
            border-bottom-color: $accent-01;
        }
    }

    &__mask {
        display: block;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 999;
    }
}

.spin {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;

    &:before {
        animation: 1.5s linear infinite spinner;
        animation-play-state: inherit;
        border: solid 5px $grey-02;
        border-bottom-color: $accent-01;
        border-radius: 50%;
        content: "";
        width: 64px;
        height: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        will-change: transform;
    }
}
</style>
