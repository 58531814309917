<template>
    <div
        :class="[{
            'field-element--required': required,
            'field-element--error': error,
            'field-element--disabled': disabled,
            'field-element--readonly': readonly,
        }, modifierClasses, 'field-element field-element--dropdown' ]"
    >

        <p class="field-helper" v-if="helper">{{ helper }}</p>

        <label
            v-if="label"
            :for="id"
            class="field-label"
        >
            {{ label }}
            <span v-if="required" class="field-label__required">required</span>
        </label>
        <select
            ref="select"
            :class="[{'unselected': !fieldValue}, 'field-input dropdown' ]"
            v-bind:id="id || name"
            v-bind:name="name"
            v-bind:placeholder="placeholder"
            v-bind:required="required"
            v-bind:disabled="disabled"
            v-bind:readonly="readonly"
            v-bind:tabindex="tabindex"
            v-model="fieldValue"
            v-on:blur="validateField"
        >
            <option
                v-if="!!placeholder"
                class="dropdown-top"
                :class="{'disabled': !fieldValue}"
                value=""
                v-bind:selected="!fieldValue"
                disabled
            >
                {{ placeholder }}
            </option>
            <option
                v-for="(option, index) in options"
                :key="index"
                v-bind:value="option.value"
                v-bind:disabled="option.disabled || option.value === null"
                v-bind:selected="option.value === value"
            >
                {{option.text}}
            </option>
        </select>
        <slot/>
        <p v-if="error" class="field-error">This field is required</p>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, ref, computed, watch, PropType } from 'vue';

export interface DropdownOption {
    value: string | number;
    text: string;
    disabled?: boolean;
}

export default defineComponent({
    name: 'FieldDropdown',
    emits: [
        'update:value',
    ],
    props: {
        id: {
            type: String,
        },
        name: {
            type: String
        },
        helper: {
            type: String
        },
        required: {
            type: Boolean
        },
        label: {
            type: String
        },
        placeholder: {
            type: String,
            default: () => 'Select an option'
        },
        disabled: {
            type: Boolean
        },
        readonly: {
            type: Boolean,
        },
        tabindex: {
            type: String,
        },
        options: {
            type: Array as PropType<DropdownOption[]>,
            required: true
        },
        modifierStyles: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        value: {
            type: [String, Number],
            default: () => "",
        }
    },
    setup(props, context) {
        const { modifierStyles, value, required } = toRefs(props);

        const internalValue = ref(props.value);
        const error = ref(false);

        watch(value, value => {
            internalValue.value = value;
            validateField();
        });

        const fieldValue = computed({
            get: () => internalValue.value,
            set: value => {
                internalValue.value = value;
                context.emit('update:value', value);
            }
        });

        const modifierClasses = computed(() => {
            return modifierStyles.value
                .map(value => 'field-element--' + value)
                .join(' ');
        });

        function validateField() {
            error.value = (
                required.value
                && !internalValue.value
            );
        }

        return {
            error,
            modifierClasses,
            fieldValue,
            validateField,
        }
    },
});

</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/forms.scss';

select::-ms-expand {
    display: none;
}

.field-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    @include field-textbox;
    @include field-styles;
    width: 100%;
    background-image:
        url(/images/fields/field_arrow-down.svg),
        url(/images/fields/field_background-fill.svg);
    background-repeat:
        no-repeat,
        repeat-Y;
    background-position:
        center right calc((40px - #{$form-icon-arrow-down}) / 2),
        center right;
    background-size:
        $form-icon-arrow-down,
        40px;
    padding-right: 52px;

    .field-element--accommodation & {
        background-image:
            url(/images/fields/field_bed.svg),
            url(/images/fields/field_arrow-down.svg),
            url(/images/fields/field_background-fill.svg);
        background-repeat:
            no-repeat,
            no-repeat,
            repeat-Y;
        background-position:
            center left 12px,
            center right calc((40px - #{$form-icon-arrow-down}) / 2),
            center right;
        background-size:
            16px,
            $form-icon-arrow-down,
            40px;
        padding-left: 40px;
        padding-right: 40px;
    }

    &[multiple] {
        background-image: none;
        padding-right: 52px;
    }

    &::-ms-expand {
        display: none;
    }
}

/* Multiple selects */
.field-element--select--multiple select {
    padding: 8px;
    background-image: none;
}

.field-element--select--multiple option {
    padding: 8px;
}

.dropdown-top {
    opacity: 0.5;
}

.field-element--white .field-input {
    border-color: $grey-02;
    background-color: $white;
}

.field-element--borderless .field-input {
    background-position: center right calc((40px - 20px) / 2), center right -2px;
}
</style>
