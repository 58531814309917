<template>
    <div class="popup-add-to-cart">
        <div class="popup-add-to-cart__heading">
            <h2 class="popup-add-to-cart__heading__text">{{ itemsString }} Added to Cart</h2>
            <Icon class="popup-add-to-cart__heading__icon" icon="shopping-cart-check"/>
        </div>
        <p>You can adjust quantities at checkout</p>

        <div class="popup-add-to-cart__item__container">
            <div
                v-for="item, index in cart.cartMeta.itemsAdded"
                :key="index"
                class="popup-add-to-cart__item__container--flex"
            >
                <Icon
                    icon="check-circle-1"
                    size="22px"
                    class="popup-add-to-cart__item__icon"
                />
                <p class="popup-add-to-cart__item__text">
                    {{ item.name }}
                    <span v-if="item.type === 'hire' || item.type === 'reservable'"> - {{ item.package_name }}</span>

                    <span class="popup-add-to-cart__item__text--lighter">
                        (x{{ item.qty }})
                    </span>

                    <div
                        v-if="item.type === 'event'"
                        class="popup-add-to-cart__item__text--lighter popup-add-to-cart__item__text--smaller"
                    >
                        {{ item.ticket_type.name }}
                    </div>
                </p>
                <p class="popup-add-to-cart__item__price">${{ (item.price * item.qty).toFixed(2) }}</p>
            </div>
        </div>

        <div class="popup-add-to-cart__cta__container">
            <Button
                class="popup-add-to-cart__cta popup-add-to-cart__cta__continue"
                label="Continue Shopping"
                @click="closePopup()"
            />
            <p>or</p>
            <Button
                class="popup-add-to-cart__cta"
                label="Go to Checkout"
                @click="closePopup({ name: 'cart' })"
            />
        </div>

    </div>

</template>

<script setup lang="ts">
import { computed } from "vue";
import { useCart } from '@/store';
import { CartItem } from "@/types";
import { useRouter, RouteLocationRaw } from "vue-router";
import Button from '@/components/atoms/Button.vue';

type CartType = CartItem['type'];

type CartTypeRoutes = {
    [K in CartType]: RouteLocationRaw;
} & { default: RouteLocationRaw }

const router = useRouter();
const cart = useCart();

// Mapping of cart item types to named routes
// Thought about doing this in some programmatic way, i.e.
// go to top level, parent route given current child route
// but Popup does not prevent navigation using the bottom navbar
// so doing it this way would not be reliable.
const cartTypeRoutes: CartTypeRoutes = {
    event:      { name: 'park-events' },
    reservable: { name: 'park-reservables' },
    product:    { name: 'shop' },
    hire:       { name: 'park-hires' },
    default:    { name: 'shop' },
};

// TODO: use i18n for pluralisation
const itemsString = computed(() => {
    if (cart.cartMeta.itemsAdded.length > 1 || cart.cartMeta.itemsAdded[0].qty > 1) {
        return 'Items';
    }

    return 'Item';
});

const closePopup = (namedRoute?: RouteLocationRaw) => {
    // 'Go to checkout' CTA will supply its own 'cart' named route
    if (namedRoute) {
        router.push(namedRoute);
    } else {
        const itemType = cart.cartMeta.itemsAdded[0].type;
        router.push(cartTypeRoutes[itemType] ?? cartTypeRoutes.default);
    }

    cart.cartMeta.itemsAdded.length = 0;
    cart.cartMeta.isItemAdded = false;
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.popup-add-to-cart {
    padding: 1rem;
    &__heading {
        display: flex;
        width: 100%;

        &__icon {
            margin-left: 1rem;
            &:deep() svg {
                width: 24px;
                height: 24px;

                path,
                ellipse,
                circle,
                line,
                rect {
                    fill: $black;
                    stroke: none;
                }
            }
        }

        &__text {
            margin-bottom: 0.5rem;
            color: $black;
        }
    }

    &__item__container {
        margin-block: 1.5rem;

        &--flex {
            display: flex;
            column-gap: 1rem;
            margin-block: 0.8rem;
            align-items: center;

            & > p {
                margin: 0;
            }
        }
    }


    &__item {
        &__icon {
            &:deep() svg {
                width: 22px;
                height: 22px;

                path {
                    stroke: $utility-positive;
                }
            }
        }

        &__text {
            color: $grey-11;
            font-weight: 500;

            &--lighter {
                color: $grey-10;
                font-weight: 400;
            }

            &--smaller {
                font-size: 12px;
                padding-top: 0.2em;
            }
        }

        &__price {
            display: flex;
            justify-content: end;
            flex-grow: 1;
        }
    }

    &__cta__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;

        // Let gap handle this
        & > * {
            margin: 0;
        }
    }

    &__cta {
        width: 100%;

        &__continue {
            background-color: $grey-06;
            border-color: $grey-06;
        }
    }
}
</style>
