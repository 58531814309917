
import { computed } from 'vue';
import { useRouter } from 'vue-router';


export function useRouterDepth() {
    const router = useRouter();

    return computed(() => {
        const { path, meta } = router.currentRoute.value;

        if (typeof meta.depth === 'number') {
            return meta.depth;
        }

        const depth = path.replace(/^\/|\/$/, '').split('/').length;
        return depth;
    });
}
