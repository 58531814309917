import { createRouter, RouteLocation, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import Scaffold from '@/components/wrappers/Scaffold.vue'
import TabGroupSub from '@/components/wrappers/TabGroupSub.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import { createAppHistory } from './createAppHistory';
import { ContentSettingType } from '@/api';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    // Unrecognized path automatically matches 404
    path: "/:catchAll(.*)",
    component: NotFoundView,
  },
  {
    path: "/welcome",
    component: Scaffold,
    props: { showHeader: false },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import('@/views/Dashboard.vue'),
      },
    ],
  },
  {
    path: "/verify",
    name: "Verification",
    component: () => import('@/views/Verification.vue')
  },
  {
    path: "/verify/check-in",
    name: "Verification Check-in",
    component: () => import('@/views/Verification.vue'),
    props: {
      jumpToStep: 2,
    },
  },
  {
    path: "/",
    component: Scaffold,
    children: [
      {
        path: "/",
        name: 'home',
        redirect: "/welcome",
      },
      {
        path: "park",
        name: "park-group",
        meta: { depth: 1, requiresTabRedirect: true },
        component: TabGroupSub,
        props: {
          items: [
            { label: 'Activities', content_setting: 'event', to: { name: 'park-events' } },
            { label: 'Hire', content_setting: 'hire', to: { name: 'park-hires' } },
            { label: 'Services', content_setting: 'reservable', to: { name: 'park-reservables' } },
          ],
        },
        children: [
          {
            path: "events",
            children: [
              {
                path: "",
                name: "park-events",
                meta: { depth: 1 },
                component: () => import('@/views/ParkEventList.vue'),
              },
              {
                path: ":id",
                name: "park-events-single",
                meta: { depth: 2 },
                component: () => import('@/views/EventSingle.vue'),
                props: route => ({ start: route.query.start || null }),
              },
              {
                path: ":id/book",
                name: "park-events-book",
                meta: { depth: 2 },
                component: () => import('@/views/ParkEventBook.vue'),
                props: route => ({ start: route.query.start || null }),
              },
            ],
          },
          {
            path: "hires",
            children: [
              {
                path: "",
                name: "park-hires",
                meta: { depth: 1 },
                component: () => import('@/views/ParkHireList.vue'),
              },
              {
                path: ":id",
                name: "hire-single",
                meta: { depth: 2 },
                component: () => import('@/views/ParkHireSingle.vue'),
              },
              {
                path: ":id/book",
                name: "hire-book",
                meta: { depth: 2 },
                component: () => import('@/views/ParkHireBook.vue'),
              },
            ]
          },
          {
            path: "bookings",
            children: [
              {
                path: "",
                name: "park-reservables",
                meta: { depth: 1 },
                component: () => import('@/views/ParkReservableList.vue'),
              },
              {
                path: ":id",
                name: "park-reservable-single",
                meta: { depth: 2 },
                component: () => import('@/views/ParkReservableSingle.vue'),
              },
              {
                path: ":id/book",
                name: "park-reservable-book",
                meta: { depth: 2 },
                component: () => import('@/views/ParkReservableBook.vue'),
              },
            ]
          },
        ]
      },
      {
        path: "local",
        name: "local-group",
        meta: { depth: 1, requiresTabRedirect: true },
        component: TabGroupSub,
        props: {
          items: [
            { label: 'Attractions', content_setting: 'explore-attractions', to: { name: 'local-attractions' } },
            { label: 'Events', content_setting: 'explore-events', to: { name: 'local-events' } },
            { label: 'Food', content_setting: 'explore-food', to: { name: 'local-food' } },
            { label: 'Deals', content_setting: 'deals', to: { name: 'deals' } },
          ],
        },
        children: [
          {
            path: "attractions",
            children: [
              {
                path: "",
                name: "local-attractions",
                meta: { depth: 1 },
                component: () => import('@/views/LocalAttractionList.vue'),
              },
              {
                path: ":id",
                name: "attraction-single",
                meta: { depth: 2 },
                component: () => import('@/views/LocalAttractionSingle.vue'),
              },
            ]
          },
          {
            path: "events",
            children: [
              {
                path: "",
                name: "local-events",
                meta: { depth: 1 },
                component: () => import('@/views/LocalEventList.vue'),
              },
              {
                path: ":id",
                name: "local-events-single",
                meta: { depth: 2 },
                component: () => import('@/views/EventSingle.vue'),
                props: route => ({ start: route.query.start || null }),
              },
            ]
          },
          {
            path: "food",
            children: [
              {
                path: "",
                name: "local-food",
                meta: { depth: 1 },
                component: () => import('@/views/LocalFoodList.vue'),
              },
              {
                path: ":id",
                name: "local-food-single",
                meta: { depth: 2 },
                component: () => import('@/views/LocalFoodSingle.vue'),
              },
            ]
          },
          {
            path: "deals",
            children: [
              {
                path: "",
                name: "deals",
                component: () => import('@/views/LocalDealsListing.vue'),
              },
              {
                path: ":id",
                name: "deal-single",
                component: () => import('@/views/LocalDealSingle.vue'),
              },
            ]
          },
        ]
      },
      {
        path: "info",
        name: "info",
        redirect: '/info/stay',
        children: [
          {
            path: "stay",
            name: "stay-information",
            meta: { depth: 1 },
            component: () => import('@/views/StayInformation.vue'),
          },
          {
            path: "facilities",
            children: [
              {
                path: "",
                name: "facilities",
                component: () => import('@/views/ParkFacilities.vue'),
              },
              {
                path: ":id",
                name: "facility-single",
                component: () => import('@/views/ParkFacilitySingle.vue'),
              },
            ],
          },
          {
            path: "faqs",
            name: "faqs",
            component: () => import('@/views/FAQs.vue'),
          },
          {
            path: "contact",
            name: "contact",
            component: () => import('@/views/Contact.vue'),
          },
          {
            path: "maps",
            name: "maps",
            component: () => import('@/views/MapsListing.vue'),
          },
          {
            path: "maps/park",
            name: "park-map",
            component: () => import('@/views/ParkMap.vue'),
          },
          {
            path: "content/:id",
            name: "content-item",
            component: () => import('@/views/CustomContent.vue'),
          },
        ]
      },
      {
        path: "map",
        name: "map",
        component: () => import('@/views/Map.vue'),
        props: route => ({ uid: route.query.uid || null }),
      },
      {
        path: "rebook",
        name: "rebook",
        component: () => import('@/views/Rebook.vue')
      },
      {
        path: "chatbot-booking-payment",
        name: "chatbot-booking",
        component: () => import('@/views/ChatbotBook.vue'),
        props: route => (
          {
            dateFrom: route.query.date_from || null,
            dateTo: route.query.date_to || null,
            adults: route.query.adults || null,
            children: route.query.children || null,
            infants: route.query.infants || null,
            categoryClass: route.query.category_class || null,
            categoryId: route.query.category_id || null,
            rateId: route.query.rate_id || null,
            siteName: route.query.site_name || null,
            siteSleeps: route.query.sites_sleeps || null,
            price: route.query.price || null,
          }
        ),
      },
      {
        path: "shop",
        name: "shop",
        component: () => import('@/views/Shop.vue')
      },
      {
        path: "chat",
        name: "chat",
        component: () => import('@/views/Chat.vue')
      },
      {
        path: "cart",
        children: [
          {
            path: "",
            name: "cart",
            component: () => import('@/views/Cart.vue'),
          },
          {
            path: "checkout",
            name: "checkout",
            meta: { depth: 2 },
            component: () => import('@/views/Checkout.vue')
          },
          {
            path: "cart-payment",
            name: "cart-payment",
            meta: { depth: 2 },
            component: () => import('@/views/CartPayment.vue')
          },
          {
            path: "cart-complete",
            name: "cart-complete",
            meta: { depth: 2 },
            component: () => import('@/views/CartComplete.vue')
          },
        ]
      },
    ],
  },
]


const history = createAppHistory(import.meta.env.BASE_URL + 'entity/' + window.__stay__.base_entity_uid);

const router = createRouter({
  history,
  routes,
})


// Not actually a hook but we'll treat it like one.
export function useHistory() {
  return history;
}

// Kinda wish we could use this interface for local-group/park-group props
// array too but seemingly no easy way to do that within Vue router(?)
// TODO: look into
interface RoutePropTabItem {
  label: string;
  content_setting: ContentSettingType;
  to: RouteLocation;
}

// Redirect to first tab that has content setting enabled
// Probably a bit hard to follow but the object structures for 'to' routes
// and to a lesser extent, the props array, go pretty deep so kinda unavoidable
const tabRedirect = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const route = to.matched[to.matched.length - 1];
  const routeProps = route.props.default;

  // Only tab group views need redirection, so manually guard other pages
  // if they (for some reason) have the meta boolean set
  if (route.name !== 'local-group' && route.name !== 'park-group') {
    return false;
  }

  for (const child of route.children) {
    // Mostly for making TypeScript happy with Vue's strict types...
    if (routeProps && typeof routeProps === 'object' && 'items' in routeProps) {
      const routePropItems: RoutePropTabItem[] = routeProps.items
      const matchingTabProp = routePropItems.find(item => item.to.name === child.children![0].name);
      const { entity } = store.state.app;

      // Full page refresh will mean our async request for entity will not be ready!
      // Not a huge deal - it just shows a blank space instead of prefilled tab
      // but we may want to look into waiting for it to exist first... TODO
      if (entity && matchingTabProp) {
        const contentSettingPropName: ContentSettingType = matchingTabProp.content_setting;

        if (child.children && entity.content_settings[contentSettingPropName].display) {
          return { name: child.children[0].name! };
        }
      }
    }
  }

  // If no redirection, return false so we can proceed with original parent route
  return false;
}

router.beforeEach((to, from, next) => {

  if (to.meta.requiresTabRedirect) {
    const redirectRoute = tabRedirect(to, from);

    if (redirectRoute) {
      next(redirectRoute);
      return;
    }
  }

  next();
});


export default router;