<template>
    <router-view v-if="busy == 'ready'" />

    <div v-else-if="busy == 'missing' && entities">
        <h3>DEV ONLY</h3>

        <ul v-for="entity in entities">
            <li><a :href="`/entity/${entity.uid}`">{{ entity.name }}</a></li>
        </ul>
    </div>

    <div v-else-if="busy == 'missing'">
        <h3>Sorry, this entity does not exist or is not configured correctly.</h3>
    </div>

    <div v-else-if="busy == 'error'">
        <h3>Sorry, there was an issue. Please wait a moment and try again.</h3>
    </div>

    <Loader v-else />

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import Header from '@/components/Header.vue';
import Navigation from '@/components/Navigation.vue';
import Loader from './components/abstracts/Loader.vue';
import { useStore } from 'vuex';
import * as API from './api';
import { State } from './store';
import Color from 'color';
import * as Sentry from "@sentry/vue";

type BusyState = 'loading' | 'missing' | 'error' | 'ready';

const SHOW_DEV = (
    import.meta.env.DEV
    || window.location.hostname === 'localhost'
    || window.location.hostname.includes('bunnyqa.com')
    || window.location.hostname.includes('bunnysites.com')
);

export default defineComponent({
    name: 'App',
    components: {
        Header,
        Navigation,
        Loader
    },
    setup(props, context) {
        const busy = ref<BusyState>('loading');
        const entities = ref<API.Entity[]>([]);
        const store = useStore<State>();

        onMounted(async () => {
            try {
                if (window.__stay__.base_entity_uid) {
                    await store.dispatch('app/init');

                    updateEntityStuff(store.state.app.entity!);

                    busy.value = 'ready';
                }
                else {
                    busy.value = 'missing';
                }
            }
            catch (error) {
                console.error(error);

                if (
                    error instanceof API.ApiError
                    && [404, 400].includes(error.status)
                ) {
                    busy.value = 'missing';
                }
                else {
                    busy.value = 'error';
                }
            }

            // Special environments get a shortcut utility.
            if (SHOW_DEV && busy.value == 'missing') {
                try {
                    entities.value = await API.getAllEntities();
                }
                catch (error) {
                    console.error(error);
                    busy.value = 'error';
                }
            }
        });

        function updateEntityStuff(entity: API.Entity) {
            window.document.title = entity.name;

            Sentry.setContext('entity', {
                uid: entity.uid,
                name: entity.name,
            });

            if (entity.logo_url) {
                const link = window.document.createElement('link');
                link.rel = 'icon';
                link.type = 'image/png';
                link.href = entity.logo_url;
                window.document.head.appendChild(link);
            }

            const manifest = window.document.createElement('link');
            manifest.rel = 'manifest';
            manifest.href = `https://stay.bunnyqa.com/pwa/${entity.uid}/app.manifest`;
            manifest.crossOrigin = 'anonymous';
            window.document.head.appendChild(manifest);

            setColor('--entity-color-1', entity.colors.color1);
            setColor('--entity-color-2', entity.colors.color2);
        }

        function setColor(name: string, value: string) {
            const color = Color(value);
            const lightness = color.hsl().array()[2];

            document.documentElement.style.setProperty(name, color.hex());
            document.documentElement.style.setProperty(name + '-dark', color.darken(0.6).hex());
            document.documentElement.style.setProperty(name + '-light', color.lightness(lightness + 30).hex());
            document.documentElement.style.setProperty(name + '-lighter', color.lightness(lightness + 45).hex());
            document.documentElement.style.setProperty(name + '-text', color.isLight() ? '#000' : '#fff');
        }

        return {
            busy,
            entities,
        }
    },
})
</script>

<style lang="scss">
@import 'assets/scss/variables.scss';

html,
#app {
    font-family: $font;
    font-size: $fs-body;
    background-color: $grey-01;
    color: $text-colour;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
    height: 100%;
}

body {
    margin: 0;
}

* {
    box-sizing: border-box;
}


.container {
    position: relative;
    z-index: 1;
    width: $container-width;
    margin: 0 auto;
    padding-bottom: $section;
}

.container--pb0 {
    padding-bottom: 0;
}

/* ---- Typography ---- */
p, ul, ol, blockquote {
    margin-top: 0;
    margin-bottom: $paragraph-break;
}

.bullet-list {
    padding-left: calc(#{$container-gutter} - 6px);

li {
    padding-inline: 6px;

        &::marker {
            content: url('/images/icon_list-bullet.svg');
        }
    }
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: $paragraph-break;
    font-weight: 600;
}

h1 {
    font-size: 20px;
    color: $black;
}

mark {
    background-color: $grey-02;
    color: $grey-12;
    padding: 0.1em 0.2em;
    border-radius: 2px;
    font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
    letter-spacing: 0.03em;
}

.text-weight--normal {
    font-weight: normal;
}

/* links & buttons */
a {
    color: $link-colour;
}

:focus-visible {
    outline-color: $accent-01;
    outline-offset: 3px;
}

a:-webkit-any-link:focus-visible {
    outline-color: $accent-01;
    outline-offset: 3px;
}

/* Page */
.page {
    position: relative;
    min-height: 100%;

    .scaffold--navigation &::after {
        min-height: calc(100% - (#{$taskbar-height} + #{$header-height} + #{$navigation-height}));
    }
}

/* images */
img {
    max-width: 100%;
}

/* ---- Utility Classes ---- */
/* visibility */
.-vis-hidden {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
</style>
