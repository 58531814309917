<template>
    <component
        :is="isRouter ? 'router-link' : isAnchor ? 'a' : 'button'"
        :to="isRouter ? link : null"
        :href="isAnchor ? link : null"
        :type="!isRouter && !isAnchor ? type : null"
        class="button"
        :class="[{ 'button--disabled': disabled }, modifierClasses ]"
        :tabindex="tabindex"
        :aria-label="ariaLabel"
        :disabled="disabled ? disabled : null"
        @click="onClick"
    >

    <Loader
        v-if="isLoading"
        class="button__spinner"
        :showText="false"
    />
    <template v-else>
        <Icon
            v-if="icon"
            :icon="icon"
        />
        {{ label }}
    </template>

    </component>
</template>

<script lang="ts">
/*
The button component can be
a Vue Router link <RouterLink>
an anchor link <a>
or a button <button>.
The on click event emit is only bound when the component is _not_ a router link or an anchor link.
*/
import { defineComponent, toRefs, computed, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import Loader from '@/components/abstracts/Loader.vue';

export default defineComponent({
    name: 'CustomButton',
    components: {
        Loader,
    },
    emits: ['click'],
    props: {
        isRouter: {
            type: Boolean,
        },
        isAnchor: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object] as PropType<RouteLocationRaw>,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        tabindex: {
            type: String,
        },
        ariaLabel: {
            type: String,
        },
        icon: {
            type: String,
        },
        type: {
            type: String,
            default: 'button',
        },
        modifierStyles: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    setup(props, context) {
        const {modifierStyles, link} = toRefs(props);

        const modifierClasses = computed(() => {
            return modifierStyles.value
                .map(value => 'button--' + value)
                .join(' ');
        });

        function onClick(event: Event) {
            if (!link.value) {
                context.emit('click', event);
            }
        }

        return {
            modifierClasses,
            onClick,
        };
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/button.scss';
    .button {
        @include button;
    }

    .button--flat {
        display: inline-grid;
        text-decoration: none;
        background: #fff;
        border: 1px solid $grey-03;
        color: $accent-01;
        border-radius: $button-radius;
        font-weight: 600;
        padding: 8px 12px;

        @if $accent-01-text == #000 {
            border: 1px solid $accent-01;
            color: $accent-01;
        }

        &:hover {
            background-color: $accent-01-lightest;
            color: $accent-01;
        }

        &:focus {
            background-color: $accent-01-light;
            border-color: $accent-01;
            color: $accent-01;
        }
    }

    .button--confirm {
        background-color: $utility-positive;
        border-color: $utility-positive;
    }

    .button--lightgrey {
        background-color: $grey-02;
        border-color: $grey-02;
        color: $accent-01;
    }

    [disabled],
    .button--disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .button__spinner {
        $size: 20px;

        &:deep() .spin {
            width: $size;
            height: $size;
        }

        &:deep() .spin::before {
            width: $size * 0.75;
            height: $size * 0.75;
            border-width: 3px;
        }
    }

</style>

