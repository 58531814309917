
/**
 * Prefixed persistent local storage.
 *
 * Pop in a 'key' prefix (the entity uid) and you're good to go.
 */
export class EntityStorage implements Storage
{
    #storage: Storage;
    #key: string;

    constructor(key: string) {
        this.#storage = window.localStorage;
        this.#key = key;
    }

    get #index(): string[] {
        return JSON.parse(this.#storage.getItem(this.#key + ':_index') ?? '[]')
    }

    get length(): number {
        return this.#index.length;
    }

    set length(value: number) {
        // do nothing.
    }

    clear(): void {
        this.#storage.setItem(this.#key, '{}');
        this.#storage.setItem(this.#key + ':_index', '[]');
    }

    getItem(key: string) {
        return this.#storage.getItem(this.#key + ':' + key);
    }

    key(index: number) {
        return this.#index[index];
    }

    removeItem(key: string): void {
        this.#storage.removeItem(this.#key + ':' + key);
        const index = this.#index.filter(k => k === key);
        this.#storage.setItem(this.#key + ':_index', JSON.stringify(index));
    }

    setItem(key: string, value: string): void {
        if (!this.#index.find(k => k === key)) {
            const index = this.#index.slice(0);
            index.push(key);
            this.#storage.setItem(this.#key + ':_index', JSON.stringify(index));
        }

        this.#storage.setItem(this.#key + ':' + key, value);
    }
}
