
declare global {
    interface AppStaticConfig {
        api_url: string;
        ws_url: string;
        base_entity_uid: string;
    }

    interface Window {
        __stay__: AppStaticConfig;
    }
}


Object.defineProperty(window, '__stay__', {
    writable: false,
    configurable: false,
    value: Object.freeze<AppStaticConfig>({
        api_url: import.meta.env.DEV && import.meta.env.VITE_API_URL
            ? import.meta.env.VITE_API_URL
            : 'https://stay.bunnyqa.com/api',
        ws_url: import.meta.env.DEV && import.meta.env.VITE_WS_URL
            ? import.meta.env.VITE_WS_URL
            : 'wss://ws.stay.bunnyqa.com:443/ws',
        base_entity_uid: window.location.pathname.split('/')[2],
    }),
});

