<template>
    <div class="popup-container">
        <div :class="[ {'popup-mask--top': topZIndex}, 'popup-mask' ]"></div>
        <div class="popup-content">
            <slot name="default">
                <h2>
                    {{ heading }}
                </h2>
                <p>
                    {{ message }}
                </p>
                <button class="popup-btn" type="button" @click="$emit('close')">
                    {{ buttonText ?? 'Close' }}
                </button>
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'Popup',
    props: {
        heading: {
            type: String
        },
        message: {
            type: String
        },
        buttonText: {
            type: String
        },
        topZIndex: {
            type: Boolean,
            default: false,
        }
    },
    emits: [
        'close',
    ],
    setup(props, context) {
    },
});

</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/button.scss';

.popup-container {

    .popup-mask {
        display: block;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 999;

        &--top {
            z-index: 9999;
        }
    }

    .popup-content {
        display: block;
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 20px;
        width: calc(100vw - 20px);
        z-index: 9999;

        .popup-btn {
            @include button;
            width:100%;
        }
    }
}
</style>
