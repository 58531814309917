import { computed } from 'vue';
import { Module, useStore } from 'vuex'
import { Booking, Entity, ParkTypeShortFormatted, SearchParams, TripStatus } from '@/types';
import type { State as RootState } from "./index";
import * as API from '@/api';

export interface State {
    // remove
    searchParams: SearchParams;

    // refactor
    displayAlert: boolean;
    tripStatus: TripStatus;

    // keep
    entity: Entity | null;
    shortEntityType: ParkTypeShortFormatted | null;
    booking: Booking | null;
}

export const EMPTY: State = {
    displayAlert: true,
    tripStatus: 'checkedin',

    searchParams: {
        dates: {
            start: new Date,
            end: new Date,
        },
        guests: {
            adults: 2,
            children: 0,
        },
    },

    entity: null,
    shortEntityType: null,
    booking: null,
}


export const module: Module<State, any> = {
    namespaced: true,
    state: () => EMPTY,
    mutations: {
        setValue(state: State, value: Partial<State>) {
            Object.assign(state, value);
        }
    },
    actions: {
        async init({ commit }) {
            const entity = await API.getEntity();
            commit('setValue', { entity });

            // Format it and store it globally here so we don't have
            // to repeat formatting logic across different views
            const shortEntityType = entity.type_short_name.charAt(0).toUpperCase() + entity.type_short_name.slice(1);
            commit('setValue', { shortEntityType });
        },
    },
}



export function useState<K extends keyof State, V extends State[K]>(key: K) {
    const store = useStore<RootState>();

    return computed<V>({
        get() {
            return store.state.app[key] as V;
        },
        set(value: any) {
            store.commit('app/setValue', { [key]: value });
        }
    });
}
