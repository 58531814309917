
/*
 * Based on this idea:
 * https://stackoverflow.com/a/29227026
 *
 * Essentially all navigation events only use 'replaceState' to maintain the URL
 * and support hydrating from a route. This gives us the power to trash the
 * navigation tree whenever we like and put the user back at square one.
 *
 * When first loaded, a single `pushState()` ensure the user doesn't
 * accidentally leave the app using the native back button (hello android).
 *
 * At all times we have 2 history states:
 * 1. the entry state (push into 2nd)
 * 2. the active state (replace only)
 *
 * In the event of a native 'back' action:
 * 1. push a fresh active state
 * 2. navigate our virtual history
 */

import { RouterHistory } from "vue-router"

// It feels like they've actively trying to stop us from writing our own
// history implementations?
type NavigationCallback = Parameters<RouterHistory['listen']>[0];
type NavigationInformation = Parameters<NavigationCallback>[2];

// THIS IS WHY ENUMS SUCK.
// type NavigationType = Pick<NavigationInformation, 'type'>['type'];
// type NavigationDirection = Pick<NavigationInformation, 'direction'>['direction'];

export enum NavigationType {
    pop = 'pop',
    push = 'push',
}

export enum NavigationDirection {
    back = 'back',
    forward = 'forward',
    unknown = '',
}

export interface AppRouterHistory extends RouterHistory {
    DEBUG: boolean;
    clear(): void;
}

interface HistoryItem {
    location: string;
    state?: any;
}

const IDENTITY = '__app_router_identity__';


function cleanSlashes(path: string) {
    return path.replace(/^\/|\/$/g, '').replace(/\/+/g, '/');
}


// This is largely reworked from the 'createMemoryHistory()'.
export function createAppHistory(base: string = ''): AppRouterHistory {
    const listeners: NavigationCallback[] = [];
    const virtualHistory: HistoryItem[] = [{location: ''}];

    let position: number = 0;

    base = '/' + cleanSlashes(base);

    function setLocation(location: string, state?: any) {
        position++;

        if (position !== virtualHistory.length) {
            // we are in the middle, we remove everything from here in the queue
            virtualHistory.splice(position);
        }

        virtualHistory.push({location, state});

        setHistoryUrl(location);
    }

    function setHistoryUrl(location: string) {
        routerHistory.DEBUG && console.log('router: setHistoryUrl', location);

        if (window.history.state !== IDENTITY) {
            window.history.replaceState(null, '', base);
            window.history.pushState(IDENTITY, '', base + location);
        } else {
            window.history.replaceState(IDENTITY, '', base + location);
        }
    }

    function triggerListeners(to: string, from: string, info: Pick<NavigationInformation, 'direction' | 'delta'>) {
        routerHistory.DEBUG && console.log('router: call listeners', listeners.length);
        for (const callback of listeners) {
            callback(to, from, { ...info, type: NavigationType.pop });
        }
    }

    function onPopState(event: PopStateEvent) {
        routerHistory.DEBUG && console.log('router: pop', event);
        console.log(virtualHistory);
        routerHistory.go(-1);
    }

    function onBeforeUnload(event: BeforeUnloadEvent) {
        routerHistory.DEBUG && console.log('router: unload', event);

        // Trash the state.
        history.replaceState(null, '', base + routerHistory.location);
    }

    const routerHistory: AppRouterHistory = {
        DEBUG: false,

        base,

        // these are rewritten by Object.defineProperty
        location: '',
        state: {},

        createHref(location) {
            return base.replace(/^[^#]+#/, '#') + location;
        },

        replace(to, data) {
            this.DEBUG && console.log('router: replace', {to, data});

            // remove current entry and decrement position
            virtualHistory.splice(position--, 1);
            setLocation(to);
        },

        push(to, data) {
            const from = this.location;

            this.DEBUG && console.log('router: push', {from, to, data});

            setLocation(to);
        },

        listen(callback) {
            const index = listeners.length;
            listeners.push(callback);

            this.DEBUG && console.log('router: register listener', {index});

            return () => {
                const index = listeners.indexOf(callback);
                this.DEBUG && console.log('router: remove listener', {index});

                if (index > -1) listeners.splice(index, 1);
            }
        },

        go(delta, shouldTrigger = true) {
            const from = this.location;

            // The built-in HTML5 treats 0 as a reload. Do we care?
            const direction = delta < 0
                ? NavigationDirection.back
                : NavigationDirection.forward;

            position = Math.max(0, Math.min(position + delta, virtualHistory.length - 1));

            setHistoryUrl(this.location);

            this.DEBUG && console.log('router: go', {delta, direction, from, to: this.location, shouldTrigger});

            if (shouldTrigger) {
                triggerListeners(this.location, from, {
                    direction,
                    delta,
                });
            }
        },

        destroy() {
            this.DEBUG && console.log('router: destroy');

            this.clear();
            listeners.splice(0, listeners.length);
            window.removeEventListener('popstate', onPopState);
            window.removeEventListener('beforeunload', onBeforeUnload);
        },

        clear() {
            this.DEBUG && console.log('router: clear', [...virtualHistory]);

            virtualHistory.splice(1, virtualHistory.length - 1);
            position = 0;
        },
    }

    Object.defineProperty(routerHistory, 'location', {
        enumerable: true,
        get: () => virtualHistory[position].location,
    });

    Object.defineProperty(routerHistory, 'state', {
        enumerable: true,
        get: () => virtualHistory[position].state,
    });

    (function() {
        virtualHistory[0].location = '/';
        virtualHistory[0].state = undefined;

        let { pathname, search } = window.location;

        if (pathname.includes(base)) {
            pathname = cleanSlashes(pathname.slice(base.length));

            // HACK for the welcome page redirect.
            // TODO have a think about how to solve this... somewhere else.
            if (pathname && pathname !== 'welcome') {
                virtualHistory.push({ location: '/' + pathname + search });
                position = 1;
            }
        }

        window.addEventListener('popstate', onPopState);
        window.addEventListener('beforeunload', onBeforeUnload);
    })();

    return routerHistory;
}
