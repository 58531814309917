import { MaybeRef, reactive, toValue } from "vue";
import { Module, useStore } from "vuex";
import { CartItem } from "../types";
import type { State as RootState } from "./index";
import { hash53 } from "../hash";

export type CartItemState = CartItem & {
    hash: string;
}

export interface State {
    items: CartItemState[];
}

export const EMPTY: State = {
    items: [],
}

// So ideally this would just be part of the state, but this cart module
// is persistent to LocalStorage, and we don't need (or *want*) temporary
// popup state data to be persistent
export interface StateMeta {
    // Storing temporary itemsAdded is a bit silly, and ideally we would just use the
    // last index of the State items array, but for events (activities) you can book
    // multiple tickets at once so using the last index array would not be enough to
    // show what was just added to the cart. TODO: Probably better to refactor CartEvent
    // so that tickets are stored in an array, associated with a unique event uid +
    // start time combination so that we *can* just use the last index of State's items array
    itemsAdded: CartItem[],
    isItemAdded: boolean,
}

export const cartMeta = reactive<StateMeta>({
    itemsAdded: [],
    isItemAdded: false,
});

export const module: Module<State, any> = {
    namespaced: true,
    state: () => EMPTY,
    mutations: {
        setValue(state: State, value: Partial<State>) {
            Object.assign(state, value);
        },
        addItem(state: State, value: CartItem) {
            const hash = getItemHash(value);

            const index = state.items.findIndex(item => item.hash === hash);

            if (index != -1) {
                state.items[index].qty += value.qty;
            }
            else {
                const item = {...value} as CartItemState;
                item.hash = hash;
                state.items.push(item);
            }
        },
        setQuantity(state: State, value: { hash: string, amount: number }) {
            const index = state.items.findIndex(item => item.hash === value.hash);

            if (index == -1) {
                return;
            }

            state.items[index].qty = value.amount;

            if (state.items[index].qty <= 0) {
                state.items.splice(index, 1);
            }
        },
        clear(state: State) {
            state.items = [];
        },
    },
    actions: {
    },
}


export function getItemHash(item: CartItem): string {

    const fields = [item.type, item.uid];

    if (item.type === 'product') {
        return hash53(fields.join(':'));
    }

    if (
        item.type === 'hire'
        || item.type === 'reservable'
        || item.type === 'event'
    ) {
        fields.push(item.time_start);
        fields.push(item.time_end);
        fields.push(item.price_uid);
        return hash53(fields.join(':'));
    }

    // @ts-expect-error
    throw new Error('Unknown item type: ' + item.type);
}


export function useCart() {
    const store = useStore<RootState>();

    function addItem(item: MaybeRef<CartItem>) {
        cartMeta.isItemAdded = true;
        cartMeta.itemsAdded.push(toValue(item)); // not ideal
        store.commit('cart/addItem', toValue(item));
    }

    function setQuantity(hash: MaybeRef<string>, amount: Number) {
        store.commit('cart/setQuantity', { hash: toValue(hash), amount: amount } );
    }

    function clear() {
        store.commit('cart/clear');
    }

    function getTotalCount() {
        let total = 0;

        for (let item of store.state.cart.items) {
            total += item.qty;
        }

        return total;
    }

    function getTotalPrice() {
        let total = 0;

        for (let item of store.state.cart.items) {
            total += item.qty * item.price;
        }

        return total;
    }

    return reactive({
        ...store.state.cart,
        cartMeta,
        addItem,
        setQuantity,
        clear,
        getTotalCount,
        getTotalPrice,
    });
}
